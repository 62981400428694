<template>
    <v-dialog v-model="dialog" persistent width="600px">
        <template v-slot:activator="{ on, attrs }">
            <v-btn :color="$variables.v.theme.primary"
                   depressed
                   v-bind="attrs"
                   v-on="on"
                   class="dialog-create-post-guidelines-button">
                {{$t("guidelines.guidelines")}}
            </v-btn>
        </template>
        <v-card flat>
            <dragger class="mr-4"></dragger>
            <v-card-title>
                <span class="headline">{{$t("guidelines.post.title")}}</span>
            </v-card-title>
            <v-card-text>
                {{$t("guidelines.post.line-1")}}<br>
                {{$t("guidelines.post.line-2")}}<br>
                <br>
                {{$t("guidelines.post.line-3")}}<br>
                {{$t("guidelines.post.line-4")}}<br>
                <br>
                {{$t("guidelines.post.line-5")}}<br>
                {{$t("guidelines.post.line-6")}}<br>
                <br>
                {{$t("guidelines.post.line-7")}}<br>
                {{$t("guidelines.post.line-8")}}<br>
                <br>
                <b>{{$t("guidelines.post.line-9")}}</b><br>
                <span class="font-italic">
                        <v-icon>mdi-arrow-right-bold</v-icon>{{$t("guidelines.post.line-10")}}<br>
                        <v-icon>mdi-arrow-right-bold</v-icon>{{$t("guidelines.post.line-11")}}<br>
                        <v-icon>mdi-arrow-right-bold</v-icon>{{$t("guidelines.post.line-12")}}<br>
                    </span>
                <br>
                {{$t("guidelines.post.line-13")}}<br>
                {{$t("guidelines.post.line-14")}}<br>
                {{$t("guidelines.post.line-15")}}<br>
                {{$t("guidelines.post.line-16")}}<br>
                {{$t("guidelines.post.line-17")}}<br>
                <br>
                {{$t("guidelines.post.line-18")}}<br>
                {{$t("guidelines.post.line-19")}}<br>
                <br>
                {{$t("guidelines.post.line-20")}}<br>
                {{$t("guidelines.post.line-21")}}<br>
                {{$t("guidelines.post.line-22")}}<br>
                {{$t("guidelines.post.line-23")}}<br>
                <br>
                {{$t("guidelines.post.line-24")}}<br>
                <br>
                {{$t("guidelines.post.line-25")}}<br>
                <b>{{$t("guidelines.post.line-26")}}</b><br>
                <br>
                <b>{{$t("guidelines.post.line-27")}}</b><br>
                <br>
                <b>{{$t("guidelines.post.line-28")}}</b><br>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1"
                       text
                       @click="dialog = false">{{$t("guidelines.accept")}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        data() {
            return {
                dialog: false,
            }
        },
    }
</script>